
import { PortableText } from '@portabletext/react'
import { Fragment, useContext, useEffect, useMemo } from 'react'
import { Helmet } from 'react-helmet'
import { useOutletContext } from 'react-router-dom'
import { JsonLd } from 'react-schemaorg'
import styled from 'styled-components'
import { SEO_IMG_SIZE, SEO_KEYS, SEO_TYPES } from '../../constants/seoConstants'
import { FONT_SIZES, SIZES } from '../../constants/stylesConstants'
import GlobalContext from '../../context/context'
import useIsMobile from '../../hooks/useIsMobile'
import parserServices from '../../services/parserServices'
import seoServices from '../../services/seoServices'
import mixins from '../../utils/mixins'
import { extract } from '../../utils/styleUtils'
import Accordion from '../common/accordion/accordion'
import GridContainer from '../common/containers/gridContainer'
import GridItem from '../common/gridItem'
import studioList from '../sanity/studioList'
import withLinks from '../sanity/withLinks'

const Studio = () => {
  const { data, swatch } = useContext(GlobalContext)
  const { className, onRendered } = useOutletContext()
  const isMobile = useIsMobile()

  useEffect(() => onRendered(), [])

  const {
    bio,
    services,
    process,
    callToAction
  } = data?.studio ?? {}

  const parsedBio = useMemo(() => parserServices.parseStudioBio(bio, isMobile), [bio])
  const BioContainer = isMobile ? BioContainerMobile : BioContainerDesktop
  const Large = isMobile ? LargeMobile : LargeDesktop
  const SectionContainer = isMobile ? Fragment : ColumnContainer
  const sectionContainerProps = isMobile ? {} : { className }
  const Section = isMobile ? MobileSection : Column
  const CtaContainer = isMobile ? CtaContainerMobile : CtaContainerDesktop

  const schema = seoServices.getWebPageSchema(
    data,
    'Studio',
    'Studio description of TK Creative.'
  )
  if (data) {
    const about = `TK Creative is ${bio}`.split(/\n+/m).map(p => p.trim()).filter(p => p).map(p => `<p>${p}</p>`)
    const pageSchema = schema['@graph'][0]
    pageSchema[SEO_KEYS.MAIN_ENTITY] = {
      '@type': SEO_TYPES.WEB_PAGE_ELEMENT,
      about
    }
    pageSchema.primaryImageOfPage = {
      '@type': SEO_TYPES.IMAGE_OBJECT,
      name: 'TK Studio About Image',
      contentUrl: parserServices.getImg(data.studio.images[0]).width(SEO_IMG_SIZE).url()
    }
  }

  parserServices.sanitizePortableText(services)
  parserServices.sanitizePortableText(callToAction)
  return (
    <>
      <Helmet>
        <title>TK Creatives | Studio</title>
        <meta name='description' content='Studio description of TK Creative.' />
      </Helmet>
      <JsonLd item={schema} />
      <BioContainer className={className}>
        <Large $color={swatch.TEXT}>
          {parsedBio}
        </Large>
      </BioContainer>
      <Container $color={swatch.TEXT}>
        <SectionContainer {...sectionContainerProps}>
          <Section className={className} $end='span 6'>
            <h2>Services</h2>
            <PortableText value={services} components={studioList} />
          </Section>
          <Section className={className} $end='span 6'>
            <h2>Process</h2>
            {process && <Accordion items={process} />}
          </Section>
        </SectionContainer>
      </Container>
      <CtaContainer $color={swatch.TEXT}>
        <Large className={className}>
          <PortableText value={callToAction} components={withLinks} />
        </Large>
      </CtaContainer>
    </>
  )
}

const BioContainerBase = styled.section`
  ${mixins.float()}
  opacity: 0;
`

const topPadding = SIZES.MARGIN.add(SIZES.PROJECT_TOP)
const minHeight = SIZES.MAIN_H.sub(topPadding)
  .add(FONT_SIZES.MEDIUM_LEADING.sub(FONT_SIZES.MEDIUM).div(2))
  .css
const BioContainerDesktop = styled(BioContainerBase)`
  font-size: ${FONT_SIZES.MEDIUM.css};
  min-height: calc(${minHeight} + ${FONT_SIZES.DESCENDER_EM}em);
  padding-top: ${topPadding.css};
  display: grid;
  height: fit-content;
  align-items: end;
`

const BioContainerMobile = styled(BioContainerBase)`
  padding-top: ${SIZES.STUDIO_TOP_MOBILE.css};
`

const LargeBase = styled.div`
  p {
    ${mixins.swatched()}
    color: ${extract('$color')};
    &:not(:first-child) {
      text-indent: 1.5em;
    }
  }
`

const LargeDesktop = styled(LargeBase)`
  p {
    line-height: ${FONT_SIZES.MEDIUM_LEADING.css};
    font-size: ${FONT_SIZES.MEDIUM.css};
  }
`

const LargeMobile = styled(LargeBase)`
  p {
    line-height: ${FONT_SIZES.MEDIUM_LEADING_MOBILE.css};
    font-size: ${FONT_SIZES.MEDIUM_MOBILE.css};
  }
`

const Container = styled.section`
  > * {
    ${mixins.float()}
    opacity: 0;
    &, a, a:visited {
      ${mixins.swatched()}
      color: ${extract('$color')};
    }

    a {
      text-decoration-line: underline;
    }
  }
`

const ColumnContainer = styled(GridContainer)`
  padding-top: ${SIZES.STUDIO_BIO_BOT.css};
`

const Column = styled(GridItem)`
  font-size: ${FONT_SIZES.REGULAR.css};
`

const MobileSection = styled.div`
  h2 {
    padding-top: ${SIZES.STUDIO_HEADER_MARGIN_TOP_MOBILE.css};
    padding-bottom: ${SIZES.STUDIO_HEADER_PADDING_BOTTOM_MOBILE.css};
  }
`

const CtaContainerBase = styled(Container)`
  div {
    transition: none;
  }
`

const CtaContainerDesktop = styled(CtaContainerBase)`
  padding-top: ${SIZES.STUDIO_CTA_TOP.css};
  padding-bottom: ${SIZES.MAIN_H.add(FONT_SIZES.SMALL_LEADING).css};
`

const CtaContainerMobile = styled(CtaContainerBase)`
  padding-top: ${SIZES.STUDIO_HEADER_MARGIN_TOP_MOBILE.css};
`

export default Studio