import _ from 'lodash'
import { useState } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { createGlobalStyle } from 'styled-components'
import { FONT_SIZES } from '../constants/stylesConstants'
import Pages from '../data/pages'
import ProtectedRoute from './common/protectedRoute'
import Login from './login'
import LandingMobile from './pages/landing/landingMobile'
import PageMobile from './pages/page/pageMobile'
import Projects from './pages/projects'
import Studio from './pages/studio'


const Mobile = () => {
  const [menuIsOpened, setMobileMenuIsOpened] = useState(false)
  const [contactIsOpened, setContactIsOpened] = useState(false)
  const handleMenuToggle = shouldOpen => setMobileMenuIsOpened(shouldOpen)
  const handleContactToggle = shouldOpen => setContactIsOpened(shouldOpen)

  return (
    <>
      <DeviceStyle />
      <Routes>
        <Route path='/' element={
          // <ProtectedRoute>
          <LandingMobile
            menuIsOpened={menuIsOpened}
            contactIsOpened={contactIsOpened}
            onMenuToggle={handleMenuToggle}
            onContactToggle={handleContactToggle} />
          // </ProtectedRoute>
        } exact />
        <Route path='/' element={
          // <ProtectedRoute>
          <PageMobile
            menuIsOpened={menuIsOpened}
            contactIsOpened={contactIsOpened}
            onMenuToggle={handleMenuToggle}
            onContactToggle={handleContactToggle} />
          // </ProtectedRoute>
        }>
          <Route path={Pages.Projects} Component={Projects} exact />
          <Route path={Pages.Studio} Component={Studio} exact />
        </Route >
        {/* <Route path='/login' Component={Login} /> */}
        < Route path='*' element={< Navigate to='/' replace />} />
      </Routes >
    </>
  )
}

const DeviceStyle = createGlobalStyle`
  p, li {
    font-size: ${FONT_SIZES.REGULAR_MOBILE.css};
    line-height: ${FONT_SIZES.REGULAR_LEADING_MOBILE.css};
  }

  h2 {
    font-size: ${FONT_SIZES.SMALL_MOBILE.css};
  }
`


export default Mobile
