import { useWindowSize } from '@uidotdev/usehooks'
import { useCallback, useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { COLORS, EASINGS, FONT_SIZES, SIZES, TIME } from '../../constants/stylesConstants'
import GlobalContext from '../../context/context'
import breakpts from '../../data/breakpoints'
import Pages from '../../data/pages'
import useIsMobile from '../../hooks/useIsMobile'
import parserServices from '../../services/parserServices'
import Size from '../../utils/helpers/size'
import mixins from '../../utils/mixins'
import { getSize } from '../../utils/sizeUtils'
import { extract, styleIf } from '../../utils/styleUtils'
import GridItem from '../common/gridItem'
import SanityImg from '../common/sanityImg'


const PRESET_WIDTHS = {
  'c08b91506bba3af3d2ddac436fad6aad20767eb2': [400, 260],
  '0ec111250537713a710e953080f2d9b509d8b6f2': [1070, 355],
  'd41c9cf3e5455295f9b808e95584d37bbc9aca0b': [585, 225],
  '7ccc367f3be21f1619262deaad7313b3aa120776': [765, 290],
  '148f22b742b93dd65e243d289e417ca469f88bf6': [1225, 390],
  'c5e43ad722349283e5cd4f293b95fdb2375f412d': [675, 225],
  '84df8b6d8ab1dc1bb747961531b942a5d69519f6': [955, 355],
  'a420a841a05eb7fe0ee742faba3bfa66a7972bfb': [755, 250],
  '0d90509f1f4594d438c0944c749dbc5ae76edf3b': [1050, 380],
  '69ce379463828f83b8f5664a0a1063f505695bd2': [550, 190]
}


const LandingCarousel = ({
  heroHeight,
  wordmarkHeight,
  triggerImgRef,
  onLoad,
  ...props
}) => {
  const { data } = useContext(GlobalContext)
  const { images } = data?.landing ?? {}
  const { height } = useWindowSize()
  const [useSvh, setUseSvh] = useState(false)

  const isMobile = useIsMobile()
  const FirstImg = isMobile ? FirstImgMobile : FirstImgDesktop
  const firstImgMaxW =
    isMobile ? SIZES.MAIN_W_MOBILE.mult(1.5) :
      SIZES.MAIN_W.sub(SIZES.LANDING_WORKMARK_W).sub(SIZES.MARGIN.mult(3))

  const lowerBreakpt = isMobile ? 'xs' : 'xl'
  const upperBreakpt = isMobile ? 'xxl' : 's'
  const EnterButton = useCallback(() => isMobile ?
    <EnterButtonMobile>
      <Link to={Pages.Studio}>Selected Projects →</Link>
    </EnterButtonMobile> :
    <EnterButtonDesktop to={Pages.Studio}>
      Selected Projects →
    </EnterButtonDesktop>, [isMobile])

  useEffect(() => {
    if (height && height <= document.documentElement.clientHeight)
      setUseSvh(true)
  }, [height])


  return (
    <Carousel
      {...props}
      $useSvh={useSvh}
      $gap={isMobile ? SIZES.LANDING_CAROUSEL_GAP_MOBILE : SIZES.LANDING_CAROUSEL_GAP}
      $firstImgMaxW={isMobile ? SIZES.MAIN_W_MOBILE.css : firstImgMaxW.css}
      $heroHeight={heroHeight}
      $wordmarkHeight={wordmarkHeight}>
      {images &&
        <SanityImg
          alt={'TK Creative Landing Image - 1'}
          width='100%'
          img={images[0].image}
          imgComponent={FirstImg}
          getSize={({ vw }) => firstImgMaxW.getValueAt(vw)}
          onLoad={onLoad} />}
      {
        images?.slice(1).map(({ image, size }, i, imgs) => {
          const { id, dimensions } = parserServices.parseImgData(image)
          const width = PRESET_WIDTHS[id]?.[isMobile ? 1 : 0] ??
            (isMobile ? 200 : 400) + (size - 1) * (isMobile ? 40 : 150)
          const aspectRatio = dimensions[1] / dimensions[0]

          const imgWidth = getSize({
            [lowerBreakpt]: width,
            [upperBreakpt]: breakpts[upperBreakpt] / breakpts[lowerBreakpt] * width
          })

          const margin = i === imgs.length - 1 ?
            SIZES.MAIN_H_MOBILE
              .sub(FONT_SIZES.REGULAR_MOBILE.mult(2))
              .sub(SIZES.MARGIN_MOBILE).value -
            imgWidth.value * aspectRatio :
            0
          return <SanityImg
            key={i}
            alt={`TK Creative Landing Image - ${i + 1}`}
            style={{ marginBottom: margin }}
            ref={i === imgs.length - 4 ? triggerImgRef : undefined}
            width={imgWidth.css}
            img={image}
            imgComponent={Img}
            getSize={({ vw }) => vw / breakpts[lowerBreakpt] * width}
          />
        })
      }
      {images && <EnterButton />}
    </Carousel>
  )
}

const Carousel = styled(GridItem)`
  ${mixins
    .chain()
    .flex('flex-end')
    .float()}
  flex-direction: column;
  /* opacity: 0; */
  opacity: 1;
  margin-top: ${({ $heroHeight }) =>
    $heroHeight ? new Size($heroHeight).add(SIZES.LANDING_CAROUSEL_FIRST_MARGIN_MOBILE).css : 0
  };

  > img {
    ${({ $gap }) => mixins.innerSpacing($gap.css)}

    &:first-child {
      max-width: ${extract('$firstImgMaxW')};
      margin-bottom: ${({ $gap }) => $gap.mult(0.5).css};
      height: ${({ $heroHeight, $wordmarkHeight }) =>
    ($heroHeight && $wordmarkHeight) ?
      `calc(100svh - ${SIZES.MARGIN_MOBILE.mult(2).add(SIZES.LANDING_CAROUSEL_FIRST_MARGIN_MOBILE.mult(2))
        .add(new Size($wordmarkHeight))
        .add(SIZES.LANDING_CAROUSEL_FIRST_MARGIN_COMPENSATION_MOBILE)
        .add(new Size($heroHeight)).css});` : ''};
      padding: ${styleIf('$useSvh', 0, 'calc((100dvh - 100svh) / 2)')} 0;
      transition: padding ${EASINGS.OUT_QUAD} ${TIME.MOBILE_VH_CHANGE}ms;
    }

    &:nth-child(2) {
      padding-top: ${({ $wordmarkHeight }) =>
    $wordmarkHeight ? new Size($wordmarkHeight)
      .add(SIZES.LANDING_CAROUSEL_FIRST_MARGIN_MOBILE.mult(2))
      .add(SIZES.LANDING_CAROUSEL_FIRST_MARGIN_COMPENSATION_MOBILE)
      .add(SIZES.MARGIN_MOBILE).css : ''};
    }
  }
`

const Img = styled.img`
  display: block;
  object-fit: cover;
  max-width: ${SIZES.MAIN_W_MOBILE.css};
`

const FirstImgDesktop = styled(Img)`
  height: ${SIZES.MAIN_H.css};
`

const FirstImgMobile = styled(Img)`
  max-width: ${SIZES.MAIN_W_MOBILE.css};
`

const EnterButtonDesktop = styled(Link)`
  ${mixins
    .chain()
    .withArrow()
    .swatched()}
  margin: ${SIZES.LANDING_ENTER_GAP.css} 0 ${SIZES.MARGIN.css};
  font-size: ${FONT_SIZES.REGULAR.css};
  line-height: 0.45em;
  color: ${COLORS.BLACK};
  pointer-events: none;
`


const EnterButtonMobile = styled.div`
  ${mixins
    .chain()
    .withArrow()
    .flex('initial', 'center')
    .swatched()}
  width: 100%;
  /* margin: ${SIZES.LANDING_ENTER_GAP_MOBILE.css} 0 ${SIZES.MARGIN_MOBILE.css}; */
  margin-bottom: ${SIZES.MARGIN_MOBILE.css};
  // margin: ${Size.subFromFullHeight(SIZES.MARGIN_MOBILE).sub(FONT_SIZES.REGULAR_MOBILE).css} 0 ${SIZES.MARGIN_MOBILE.css};
  font-size: ${FONT_SIZES.REGULAR_MOBILE.css};
  line-height: 100%;
  pointer-events: none;
  a {
    color: ${COLORS.BLACK};
  }
`

export default LandingCarousel