export const SEO_TYPES = {
  WEBSITE: 'WebSite',
  WEBPAGE: 'WebPage',
  ABOUT_PAGE: 'AboutPage',
  POSTAL_ADDRESS: 'PostalAddress',
  LOCAL_BUSINESS: 'LocalBusiness',
  WEB_PAGE_ELEMENT: 'WebPageElement',
  ITEM_LIST: 'ItemList',
  LIST_ITEM: 'ListItem',
  IMAGE_OBJECT: 'ImageObject'
}

export const SEO = {
  NAME: 'TK Creative | Interior Design',
  AUTHOR: 'Terra Kushner',
  DESCRIPTION: 'TK Creative is a design studio, based in Toronto. We design residential and commercial interior environments that transform visions into experiences.',
  KEYWORDS: [
    'Interior Design Toronto',
    'Home Improvement Toronto',
    'Space Planning Toronto',
    'Experiential Design',
    'Residential Interior Design',
    'Commercial Interior Design',
    'TK Creative Design'
  ]
}

export const WEBSITE_SEO = {
  '@type': SEO_TYPES.WEBSITE,
  author: SEO.AUTHOR,
  name: SEO.NAME,
  description: SEO.DESCRIPTION,
}

export const SEO_KEYS = {
  MAIN_ENTITY: 'mainEntity',
}

export const SEO_IMG_SIZE = 600