import { useClickAway, useWindowSize } from '@uidotdev/usehooks'
import { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { SIZES, TIME } from '../../constants/stylesConstants'
import GlobalContext from '../../context/context'
import useInterval from '../../hooks/useInterval'
import useIsMobile from '../../hooks/useIsMobile'
import { fallback, mod } from '../../utils/commonUtils'
import mixins from '../../utils/mixins'
import { extract } from '../../utils/styleUtils'
import SanityImg from '../common/sanityImg'
import parserServices from '../../services/parserServices'
import { getVw } from '../../utils/sizeUtils'
import { addEventListener } from '../../utils/reactUtils'



const StudioCarousel = () => {
  const { data } = useContext(GlobalContext)

  const [isHovering, setIsHovering] = useState(false)
  const [carouselLeft, setCarouselLeft] = useState()
  const [carouselTop, setCarouselTop] = useState()
  const [src, setSrc] = useState(0)
  const { width } = useWindowSize()

  const { images } = data?.studio ?? {}
  const isMobile = useIsMobile()
  const containerRef = useClickAway(() => setIsHovering(false))

  useEffect(() => setIsHovering(false), [width])
  const handleHover = (e, isHover) => {
    if (!isHover) {
      setIsHovering(false)
      return setCarouselLeft()
    }
    setIsHovering(true)

    const clientRect = e.target.getBoundingClientRect()
    const top = e.target.offsetTop + SIZES.MARGIN_MOBILE.value + clientRect.height / 2
    setCarouselTop(`${top}px`)
    setCarouselLeft(
      isMobile ?
        getVw(50) :
        clientRect.right + SIZES.GAP.value
    )
  }

  useEffect(() => addEventListener(window, 'scroll', e => handleHover(e, false)), [])

  const intervalRef = useInterval(() => {
    if (images && isHovering) setSrc(prev => mod(prev + 1, images.length))
  }, TIME.STUDIO, [isHovering])

  useEffect(() => {
    if (!isHovering) {
      setSrc(0)
      clearInterval(intervalRef.current)
    }
  }, [isHovering])

  const heightVertical = isMobile ?
    SIZES.STUDIO_CAROUSEL_MAX_HEIGHT_MOBILE_VERTICAL :
    SIZES.STUDIO_CAROUSEL_MAX_HEIGHT_VERTICAL

  const heightHorizontal = isMobile ?
    SIZES.STUDIO_CAROUSEL_MAX_HEIGHT_MOBILE_HORIZONTAL :
    SIZES.STUDIO_CAROUSEL_MAX_HEIGHT_HORIZONTAL

  const handleClick = e => {
    if (e.target === containerRef.current)
      handleHover(e, true)
  }

  return (
    <Container
      ref={containerRef}
      onMouseEnter={e => handleHover(e, true)}
      onMouseLeave={e => handleHover(e, false)}
      onClick={handleClick}>
      {isMobile && 'TK Creative '}<sup>[↗]</sup>
      <Carousel
        $left={fallback(carouselLeft, `${carouselLeft}px`)}
        $top={isMobile ? carouselTop : SIZES.STUDIO_CAROUSEL_TOP.css}
        $transform={fallback(isMobile, 'translate(-50%, -50%)')}>
        {images?.map((img, i) => {
          const { dimensions } = parserServices.parseImgData(img)
          const aspectRatio = dimensions[0] / dimensions[1]
          return <SanityImg
            key={i}
            alt={`TK Creative Studio Image - ${i + 1}`}
            style={{
              display: (isHovering && src === i) ? '' : 'none',
              maxHeight: (aspectRatio > 1 ? heightHorizontal : heightVertical).css
            }}
            img={img}
            getSize={({ vw, aspectRatio }) =>
              (aspectRatio > 1 ? heightHorizontal : heightVertical)
                .getValueAt(vw) * aspectRatio} />
        })}
      </Carousel>
    </Container>
  )
}

const Container = styled.span`
  cursor: default;
  ${mixins.withArrow}
  sup {
    font-size: 0.65em;
    vertical-align: 0.75em;
  }
`

const Carousel = styled.span`
  ${mixins.highZIndex(2)};
  display: block;
  position: fixed;
  top: ${extract('$top')};
  left: ${extract('$left')};

  img {
    position: absolute;
    transform: ${extract('$transform')};
  }
`

export default StudioCarousel