import _ from 'lodash'
import { Helmet } from 'react-helmet'
import { JsonLd } from 'react-schemaorg'
import styled from 'styled-components'
import { COLORS, FONT_SIZES, LANDING_ANIMATIONS, SIZES } from '../../../constants/stylesConstants'
import useLanding from '../../../hooks/useLanding'
import parserServices from '../../../services/parserServices'
import seoServices from '../../../services/seoServices'
import LandingCarousel from '../../carousels/landingCarousel'
import Background from '../../common/background'
import FullContainer from '../../common/containers/fullContainer'
import GridItem from '../../common/gridItem'
import WordMark from '../../common/wordmark'
import LandingInnerContainer from './landingInnerContainer'



const LandingDesktop = () => {
  const {
    data,
    heroCopy,
    containerRef,
    handleClick,
    handleLoaded,
  } = useLanding()

  return (
    <>
      <Helmet>
        <title>TK Creatives | Interior Design</title>
        <meta name='description' content='TK Creative is a design studio, based in Toronto. We design residential and commercial interior environments that transform visions into experiences.' />
      </Helmet>
      <JsonLd item={seoServices.getLandingSchema(data)} />
      <Container ref={containerRef} onClick={handleClick}>
        <LandingCarousel
          className={LANDING_ANIMATIONS[2].cls}
          onLoad={handleLoaded} />
        <InnerContainer>
          <HeroCopy as='p' $end='span 5' className={LANDING_ANIMATIONS[1].cls}>
            {parserServices.parseEmDash(heroCopy)}
          </HeroCopy>
          <WordMarkContainer $start='1' $end='6' className={LANDING_ANIMATIONS[0].cls}>
            <h1><WordMark /></h1>
          </WordMarkContainer>
        </InnerContainer>
        <Background color={COLORS.WHITE} />
      </Container>
    </>
  )
}

const Container = styled(FullContainer)`
  cursor: pointer;
`

const InnerContainer = styled(LandingInnerContainer)`
  width: ${SIZES.MAIN_W.css};
  padding: ${SIZES.MARGIN.css} 0;
`

const HeroCopy = styled(GridItem)`
  width: ${SIZES.HERO_COPY_W.css};
  opacity: 0;
  font-size: ${FONT_SIZES.REGULAR.css};
  line-height: ${FONT_SIZES.REGULAR_LEADING.css};
`

const WordMarkContainer = styled(GridItem)`
  position: absolute;
  bottom: ${SIZES.MARGIN.sub(SIZES.LANDING_WORKMARK_W.mult(0.005)).css};
  opacity: 0;
  display: flex;

  h1 {
   line-height:0;
  }

  svg {
    width: ${SIZES.LANDING_WORKMARK_W.css};
    height: auto;
  }
`

export default LandingDesktop